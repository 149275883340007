export const {
  OK,
  MOVED_PERMANENTLY, FOUND, TEMPORARY,
  BAD_REQUEST, NOT_FOUND,
  INTERNAL_SERVER_ERROR,
  NOT_MODIFIED,
} = {
  OK: 200,
  MOVED_PERMANENTLY: 301, FOUND: 302, TEMPORARY: 307,
  BAD_REQUEST: 400, NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  NOT_MODIFIED: 304
}
