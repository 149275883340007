export function cookieConfigurationEvent(configuration) {
  return {
    event: 'cookie_configuration_changed',
    ...cookieConfiguration(configuration).cookieConfiguration
  }
}

export function cookieConfiguration({ permissions }) {
  return {
    cookieConfiguration: {
      analytics_storage: 'denied',
      ...permissions,
      // TODO use classifications RABOCO-308
      personalization_storage: 'denied',
      security_storage: 'granted',
    },
  }
}
