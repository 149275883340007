import { v4 as uid } from 'uuid'

export function image({ src }) {
  return {
    id: uid(),
    _type: 'image',
    asset: { _ref: src },
    hotspot: {
      width: 1,
      x: 0.5,
      y: 0.5,
      height: 1,
    },
    crop: { right: 0, top: 0, left: 0, bottom: 0 }
  }
}
