import { useLocationMatch } from '@kaliber/routing'

/** @type {React.Context<any>} */
const paramsContext = React.createContext({})

// TODO: Erik - move this to @kaliber/routing

export function ParamsContext({ children }) {
  const { params } = useLocationMatch()

  const previousParamsRef = React.useRef(params)
  if (!shallowEqual(previousParamsRef.current, params)) previousParamsRef.current = params

  return <paramsContext.Provider value={previousParamsRef.current} {...{ children }} />
}

export function useParams() {
  return React.useContext(paramsContext)
}

function shallowEqual(o1, o2) {
  const o1Keys = Object.keys(o1)
  return (
    o1Keys.length === Object.keys(o2).length &&
    o1Keys.every(key => o2.hasOwnProperty(key) && o1[key] === o2[key])
  )
}
