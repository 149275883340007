import nl from './nl.po'

import { sprintf } from 'sprintf-js'

const translations = { nl }

export function translateSingular(language, id, args = {}, context = '') {
  return translatePlural(language, id, 1, args, context)
}

export function translatePlural(language, id, count, args = {}, context = '') {
  const isPlural = count !== 1 // This could be determined by parsing the plural-forms header
  const [singular, plural] = translations[language]?.translations[context]?.[id]?.msgstr ?? []

  const msg = isPlural ? plural : singular
  try {
    if (msg === undefined) throw new Error('No translation found')

    return sprintf(msg, args)
  } catch (e) {
    throw new Error(`Failed to translate '${id}'${msg ? `('${msg}')` : ''} into '${language}' (count: ${count}, args: ${JSON.stringify(args)}): ${e}`)
  }
}
